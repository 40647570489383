import englishTranslations from "./en.json";
import germanTranslations from "./de.json";
import frenchTranslations from "./fr.json";

const resources = {
    en: {
        translation: englishTranslations,
    },
    de: {
        translation: germanTranslations,
    },
    fr: {
        translation: frenchTranslations,
    },
};

export default resources;
