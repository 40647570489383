import classNames from "classnames";
import "./Asset.css";

function BaseImageAsset(src: string, alt?: string, extraClassName?: string) {
    return function ImageAsset(props: { className?: string }) {
        const { className } = props;
        return (
            <div
                className={classNames(
                    "web-lib__asset",
                    extraClassName,
                    className,
                )}
            >
                <img src={src} alt={alt ?? undefined} />
            </div>
        );
    };
}

const Asset = {
    AlbertineEmblem: BaseImageAsset(
        "https://ik.imagekit.io/albertine/share/albertine-emblem.svg",
        "Albertine",
    ),
    AlbertineTextLogo: BaseImageAsset(
        "https://ik.imagekit.io/albertine/share/albertine-logo-text.svg",
        "Albertine",
    ),
    AlbertineLogo: BaseImageAsset(
        "https://ik.imagekit.io/albertine/share/albertine-logo.svg",
        "Albertine",
    ),
    PoweredByAlbertine: BaseImageAsset(
        "https://ik.imagekit.io/albertine/share/powered-by-albertine.svg",
        "Powered by Albertine",
    ),
    HBXPConciergeLogo: BaseImageAsset(
        "https://ik.imagekit.io/albertine/share/hb-xp-concierge-logo.svg",
        "Hugo Boss XP Concierge",
        "web-lib__asset-hb-logo",
    ),
    HBEmblem: BaseImageAsset(
        "https://ik.imagekit.io/albertine/share/hb-tenant-avatar.svg",
        "Hugo Boss XP Concierge",
    ),
};

export default Asset;
