import "../css/reset.css";
import "../css/variables.albertine.css";
import "../css/variables.hugoboss.css";
import ReactDOM from "react-dom";
import templatesConfig from "./templatesConfig";
import NotFoundScreen, { NotFoundScreenProps } from "../screens/NotFoundScreen";

document.addEventListener("DOMContentLoaded", () => {
    const initialStateScript = document.getElementById(
        templatesConfig.initialStateJsonId,
    ) as HTMLScriptElement;
    const initialState = JSON.parse(
        initialStateScript.innerHTML,
    ) as NotFoundScreenProps;
    ReactDOM.hydrate(
        <NotFoundScreen {...initialState} />,
        document.getElementById(templatesConfig.reactRootId),
    );
});
