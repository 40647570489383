import React, { createContext, useContext, useMemo } from "react";
import { z } from "zod";
import { ServiceLanguage } from "../albertine-i18n";

export const Tenant = z.enum(["albertine", "hugoboss"]);
export type Tenant = z.infer<typeof Tenant>;

type EnvSpecific<T> = {
    localhost: T;
    dev: T;
    staging: T;
    prod: T;
};

type TenantSpecific<T> = { [key in Tenant]: T };
type ServiceLanguageSpecific<T> = { [key in ServiceLanguage]: T };

export type TenantConfig = {
    authentication: {
        webUrl: EnvSpecific<string>;
        // TODO: Firebase Dynamic links are deprecated in August 2025
        iosDynamicLink: EnvSpecific<string>;
        iosBundleId: string;
    };
    allowedServiceLanguages: ServiceLanguage[];
    htmlFavicon: string;
    serviceName: string;
    serviceMarketingUrl: string;
    iosOpenAppDynamicLink: EnvSpecific<string>;
    publicShareUrlBase: EnvSpecific<string>;
    emailService: string;
    emailTemplates: ServiceLanguageSpecific<{
        messageRenotification: {
            subject: string;
            template: string;
        };
        emailSignInLink: {
            subject: string;
            template: string;
        };
    }>;
};

type TenantContextType = {
    tenant: Tenant;
    tenantConfig: TenantConfig;
};

const TenantConfigs: TenantSpecific<TenantConfig> = {
    albertine: {
        authentication: {
            webUrl: {
                localhost: "http://localhost:3001/",
                dev: "https://albertine-dev-concierge.firebaseapp.com/",
                staging: "https://albertine-staging-concierge.firebaseapp.com/",
                // TODO: prod needs to be changed to concierge.albertineapp.com when the app is ready
                prod: "https://www.albertineapp.com/",
            },

            iosDynamicLink: {
                localhost: "albertineappdevelopment.page.link",
                dev: "albertineappdevelopment.page.link",
                staging: "albertineappstaging.page.link",
                prod: "albertineapp.page.link",
            },
            iosBundleId: "com.albertineapp.albertineapp",
        },
        allowedServiceLanguages: ["en"],
        htmlFavicon: "favicon-152.png",
        serviceName: "Albertine",
        serviceMarketingUrl: "https://albertineapp.com",
        // TODO: Should be changed to HB equivalent links when they are added into firebase and ios app
        iosOpenAppDynamicLink: {
            localhost: "albertineappdevelopment.page.link/open",
            dev: "albertineappdevelopment.page.link/open",
            staging: "albertineappstaging.page.link/open",
            prod: "albertineapp.page.link/open",
        },
        publicShareUrlBase: {
            localhost:
                "http://localhost:5001/albertine-dev/europe-west1/publicShare",
            dev: "https://europe-west1-albertine-dev.cloudfunctions.net/publicShare",
            staging:
                "https://europe-west1-albertine-staging.cloudfunctions.net/publicShare",
            prod: "https://concierge.albertineapp.com",
        },
        emailService: "membership@albertineapp.com",
        emailTemplates: {
            en: {
                messageRenotification: {
                    subject: "email_templates.message_renotification_subject",
                    template: "albertine-resend-message-as-email-template-en",
                },
                emailSignInLink: {
                    subject: "email_templates.email_sign_in_link_subject",
                    template: "albertine-email-sign-in-link-en",
                },
            },
            de: {
                messageRenotification: {
                    subject: "email_templates.message_renotification_subject",
                    template: "albertine-resend-message-as-email-template-de",
                },
                emailSignInLink: {
                    subject: "email_templates.email_sign_in_link_subject",
                    template: "albertine-email-sign-in-link-de",
                },
            },
            fr: {
                messageRenotification: {
                    subject: "email_templates.message_renotification_subject",
                    template: "albertine-resend-message-as-email-template-fr",
                },
                emailSignInLink: {
                    subject: "email_templates.email_sign_in_link_subject",
                    template: "albertine-email-sign-in-link-fr",
                },
            },
        },
    },

    hugoboss: {
        authentication: {
            webUrl: {
                localhost: "http://localhost:3002/",
                dev: "https://albertine-dev-concierge-hugoboss.firebaseapp.com/",
                staging: "https://albertine-staging-concierge.firebaseapp.com/",
                // TODO: prod needs to be changed to concierge.hugoboss.com when the app is ready
                prod: "https://www.albertineapp.com/",
            },
            // TODO: Should be changed to HB equivalent links when they are added into firebase and ios app
            iosDynamicLink: {
                localhost: "albertineappdevelopment.page.link",
                dev: "albertineappdevelopment.page.link",
                staging: "albertineappstaging.page.link",
                prod: "albertineapp.page.link",
            },
            iosBundleId: "com.hugoboss.hugoboss-concierge",
        },
        allowedServiceLanguages: ["de", "en", "fr"],
        htmlFavicon: "favicon-152-mc.png",
        serviceName: "Hugo Boss XP Concierge",
        serviceMarketingUrl: "https://www.hugoboss.com/uk/hugoboss-xp/",
        iosOpenAppDynamicLink: {
            localhost: "albertineappdevelopment.page.link/open",
            dev: "albertineappdevelopment.page.link/open",
            staging: "albertineappstaging.page.link/open",
            prod: "albertineapp.page.link/open",
        },
        publicShareUrlBase: {
            localhost:
                "http://localhost:5001/albertine-dev/europe-west1/publicShare",
            dev: "https://europe-west1-albertine-dev.cloudfunctions.net/publicShare",
            staging:
                "https://europe-west1-albertine-staging.cloudfunctions.net/publicShare",
            prod: "https://concierge.hugoboss.com",
        },
        emailService: "info@concierge.hugoboss.com",
        emailTemplates: {
            en: {
                messageRenotification: {
                    subject: "email_templates.message_renotification_subject",
                    template: "hb-resend-message-as-email-template-en",
                },
                emailSignInLink: {
                    subject: "email_templates.email_sign_in_link_subject",
                    template: "hb-email-sign-in-link-en",
                },
            },
            de: {
                messageRenotification: {
                    subject: "email_templates.message_renotification_subject",
                    template: "hb-resend-message-as-email-template-de",
                },
                emailSignInLink: {
                    subject: "email_templates.email_sign_in_link_subject",
                    template: "hb-email-sign-in-link-de",
                },
            },
            fr: {
                messageRenotification: {
                    subject: "email_templates.message_renotification_subject",
                    template: "hb-resend-message-as-email-template-fr",
                },
                emailSignInLink: {
                    subject: "email_templates.email_sign_in_link_subject",
                    template: "hb-email-sign-in-link-fr",
                },
            },
        },
    },
};

const AuthContext = createContext<TenantContextType | undefined>(undefined);

export function TenantProvider(props: {
    value: Tenant;
    children: React.ReactNode;
}) {
    const { children, value } = props;

    const memoizedValue = useMemo(
        () => ({
            tenant: value,
            tenantConfig: TenantConfigs[value] || TenantConfigs.albertine,
        }),
        [value],
    );

    return (
        <AuthContext.Provider value={memoizedValue}>
            {children}
        </AuthContext.Provider>
    );
}

export const useTenant = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useTenant must be used within an TenantProvider");
    }
    return context;
};

export function findMatchingTenant(membershipTier: string | undefined): Tenant {
    if (!membershipTier) {
        return "albertine";
    }
    const matchingTenant = Tenant.options.find((it) => it === membershipTier);

    return matchingTenant || "albertine";
}

export function isTenant(possibleTenant: any): possibleTenant is Tenant {
    return Tenant.safeParse(possibleTenant).success;
}

/* Promise so that we can in the future fetch this over network if needed */
export async function getTenantConfig(tenant: Tenant): Promise<TenantConfig> {
    return Promise.resolve(TenantConfigs[tenant] || TenantConfigs.albertine);
}
