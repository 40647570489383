{
    "allow_notifications": "Send me notifications",
    "albertine-user-name": "Albertine",
    "conversations_screen_title": "Your conversations",
    "bookings_screen_title": "Your bookings",
    "welcome_user": "Welcome, {{username}}!",
    "navigation_title_discover": "Discover",
    "navigation_title_bookings": "Bookings",
    "navigation_title_conversations": "Conversations",
    "navigation_title_profile": "Profile",
    "chat_input__placeholder": "Type your message...",
    "login__albertine_slogan": "The invite-only lifestyle concierge",
    "login__get_started": "Get started",
    "login__join_text": "Join the waitlist",
    "login_form__title_sms":"Let's start with your phone number",
    "login_form__description_sms":"Please enter the same phone number you registered with.",
    "login_form__title_code":"Enter your verification code",
    "login_form__email_login_link_text": "Email me sign-in link instead",
    "login_form__description_code":"Your code should arrive by SMS withing the next 30 seconds.",
    "login_form__continue":"Continue",
    "login_form__verify":"Verify",
    "login_form__title_email": "Sign in with email",
    "login_form__description_email": "Enter the email address you registered with to receive a sign-in link.",
    "login_form__title_link": "Please check your email for a sign-in link",
    "login_form__description_link": "If you are still not able to sign in, please check that the phone number and email entered match the ones that you used to register for your membership.\n\nFor further information, you can email us at <0>membership@albertineapp.com</0>.\n\nIf you are not an Albertine member yet, we invite you to join our waitlist.",
    "login_form__open_email": "Open email",
    "login_form__tag_sign_in":"Sign in",
    "login_form__tag_verification":"Verification",
    "login_form__tag_error_incorrect_number": "Incorrect number",
    "login_form__tag_error_incorrect_code": "Incorrect code",
    "errorMessage": "Something went wrong",
    "conversations__your-requests": "Your requests",
    "conversations__closed-requests": "Closed requests",
    "conversations__request-or-send": "Make a request or send a message",
    "conversations__new-messages": "New messages",
    "conversations__request-received": "New request received",
    "conversations__requests-review": "Please review",
    "conversations__requests-in-progress": "In progress",
    "conversations__requests-confirmed": "Confirmed requests",
    "conversations__request-confirmed": "Confirmed",
    "conversations__deadline-next-update": "Next update: {{deadlineText}}",
    "conversations__deadline-overdue": "Soon",
    "conversations__deadline-minutes:one": "{{count}} minute",
    "conversations__deadline-minutes:other": "{{count}} minutes",
    "conversations__deadline-hours:one": "{{count}} hour",
    "conversations__deadline-hours:other": "{{count}} hours",
    "conversations__deadline-days:one": "{{count}} day",
    "conversations__deadline-days:other": "{{count}} days",
    "chat__header": "Albertine",
    "chat__all_messages": "All messages",
    "chat__new_request": "New request",
    "chat__reply_title": "Reply",
    "chat__proposal-title": "Options",
    "chat__confirmed-booking-title": "Confirmed",
    "today": "Today",
    "yesterday": "Yesterday",
    "public_booking__confirmed": "Confirmed",
    "public_booking__address-visit-website": "Visit website",
    "public_booking__address-call": "Call",
    "public_booking__address-view-on-map": "View on map",
    "public_booking__attachments": "Attachments",
    "public_booking__private-attachments-description": "To ensure the security of your personal data, some attachments can only be opened through the Albertine app.",
    "public_booking__private-attachments-private": "(private)",
    "public_booking__accommodation-details": "Accommodation details",
    "public_booking__accommodation-details__check-in": "Check in",
    "public_booking__accommodation-details__check-out": "Check out",
    "public_booking__accommodation-details__details": "Details",
    "public_booking__concierge-benefit": "Concierge benefit",
    "public_booking__total-price": "Total price",
    "public_booking__booking-policy": "Booking policy",
    "public_booking__visible-in-app-only": "Visible in the app only",
    "public_booking__notes-from-the-team": "Notes from the team",
    "public_proposal__options": "Options",
    "public-learn-more__description": "Albertine is an invitation-only concierge service that accepts a limited number of members based on referrals.",
    "public-learn-more__request": "Learn more & request an invite",
    "public-footer__copyright": "Albertine, {{currentYear}}. All rights reserved.",
    "public-footer__hb-service-description": "Part of HUGO BOSS XP, the exclusive membership program that unlocks access to the world of BOSS and HUGO.",
    "public-footer__learn-more-about-hb": "Learn more about Hugo Boss XP",
    "page-not-found__title": "Page not found",
    "page-not-found__heading": "Looks like this content has expired",
    "page-not-found__description": "For security reasons, shared content expires after 90 days",
    "number-of-quests:one": "{{count}} guest",
    "number-of-quests:other": "{{count}} guests",
    "number-of-nights:one": "{{count}} night",
    "number-of-nights:other": "{{count}} nights",
    "notifications__tag":"Notifications",
    "notifications__title": "Please turn on notifications so we can communicate",
    "notifications__description": "We often communicate about time-sensitive topics like last-minute bookings, and we don't want you to miss out.",
    "notifications__button": "Continue",
    "location__tag": "Location Sharing",
    "location__title": "Please allow location sharing for guidance and recommendations",
    "location__description": "Please allow location sharing on the next screen, so we can provide guidance and recommendations wherever you are in the world.",
    "location__button": "Continue",
    "profile__profile": "Profile",
    "profile__logout": "Logout",
    "profile__terms-and-conditions": "Terms & Conditions",
    "profile__privacy-policy": "Privacy Policy",
    "profile__membership": "Membership",
    "profile__member-since": "Member since {{ memberSince }}",
    "profile__membership-renewal": "Membership renews on {{ membershipRenewal }}",
    "profile__footer-copyright": "© {{ currentYear }} Albertine Ltd. All rights reserved",
    "profile__feedback": "Feedback",
    "email_templates": {
      "message_renotification_subject": "Message from {{serviceName}}",
      "email_sign_in_link_subject": "Sign in to {{serviceName}}"
    },
    "bookings__bookings": "Bookings",
    "bookings__your_confirmed_bookings": "your confirmed bookings",
    "bookings__your_history": "your history",
    "bookings__confirmed": "confirmed",
    "bookings__empty_state_title": "Where next?",
    "bookings__empty_state_description": "Your team is on hand to assist with bookings and recommendations.",
    "bookings__empty_state_button": "place a request",
    "albertine__terms-and-conditions": "Albertine app - Terms and conditions",
    "albertine__privacy-policy": "Albertine app - Privacy policy",
    "back" : "Back",
    "share": "Share"
}
