import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./resources";
import { defaultServiceLanguage } from "./ServiceLanguage";

i18n.use(initReactI18next).init({
    fallbackLng: defaultServiceLanguage,
    pluralSeparator: ":",
    resources,
    interpolation: {
        escapeValue: false, // React already safes from xss
    },
});

export default i18n;
