import { I18nextProvider, useTranslation, Trans } from "react-i18next";
import { ReactNode } from "react";
import i18n from "./i18n";
import {
    getLocale,
    ServiceLanguage,
    ServiceLanguageToLocaleMap,
} from "./ServiceLanguage";

type TranslationProviderProps = {
    children: ReactNode;
    initialLanguage?: ServiceLanguage;
};

export function AlbertineI18nProvider(props: TranslationProviderProps) {
    const { children, initialLanguage } = props;

    if (initialLanguage && i18n.language !== initialLanguage) {
        i18n.changeLanguage(initialLanguage);
    }

    return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}

export function useAlbertineTranslation() {
    const { t } = useTranslation();

    return t;
}

export function changeUILanguage(locale: ServiceLanguage) {
    i18n.changeLanguage(locale);
}

export const translation = i18n.t;

export function getUILanguage() {
    const lang = getLocale(i18n.language);
    return {
        lang,
        dateFnsLocale: ServiceLanguageToLocaleMap[lang],
    };
}

export { Trans };
