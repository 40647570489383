import Asset from "../components/Asset";
import Background from "../components/Background";
import Button from "../components/Button";
import ContentCenter from "../components/ContentCenter";
import { Flex } from "../components/Flex";
import Line from "../components/Line";
import Paragraph from "../components/Paragraph";
import { useAlbertineTranslation } from "../package/albertine-i18n";
import "./AlbertineLearnMore.css";

export default function AlbertineLearnMore() {
    const t = useAlbertineTranslation();
    return (
        <Background.BookingBg02>
            <ContentCenter className="albertine__learn-more">
                <Flex.Column alignItems="center" gap="value24">
                    <Flex.Column alignItems="center" gap="value24">
                        <Asset.AlbertineLogo />
                    </Flex.Column>

                    <Flex.Column alignItems="center" gap="value24">
                        <Paragraph.Large.Reqular.TextColor01>
                            {t("public-learn-more__description")}
                        </Paragraph.Large.Reqular.TextColor01>
                        <Line.Vertical />
                        <Button.Primary
                            label={t("public-learn-more__request")}
                            onClick={() =>
                                window.open(
                                    "https://www.albertineapp.com",
                                    "_blank",
                                )
                            }
                        />
                    </Flex.Column>
                </Flex.Column>
            </ContentCenter>
        </Background.BookingBg02>
    );
}
