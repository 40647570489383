import "./TextLink.css";
import { FlexInline } from "./Flex";
import Label from "./Label";

interface Props {
    href: string;
    target?: React.HTMLAttributeAnchorTarget;
    onClick?: () => void;
    children?: React.ReactNode;
}

function TextLink(props: Props) {
    const { href, target = "_blank", onClick, children } = props;
    const onLinkClick = onClick
        ? (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              event.preventDefault();
              onClick();
          }
        : undefined;
    return (
        <a
            href={href}
            target={target}
            rel="noreferrer"
            className="web-lib__text-link"
            onClick={onLinkClick}
        >
            <FlexInline wrap="nowrap" gap="value8">
                <Label.Medium.MediumBold.TextColor01
                    nowrap
                    className="web-lib__text-link__label"
                >
                    {children}
                </Label.Medium.MediumBold.TextColor01>
            </FlexInline>
        </a>
    );
}

export default TextLink;
