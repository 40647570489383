import classNames from "classnames";
import "./Label.css";

export interface Props {
    className?: string;
    fontStyle?: "italic";
    transform?: "uppercase";
    disabled?: boolean;
    children: React.ReactNode;
    nowrap?: boolean;
}

type BaseLabel = (className?: string) => (props: Props) => JSX.Element;

function BaseLabel(baseClassName?: string) {
    return function Label(props: Props) {
        const { className, children, fontStyle, transform, disabled, nowrap } =
            props;
        return (
            <span
                className={classNames(
                    "web-lib__label",
                    baseClassName,
                    {
                        "web-lib__label--disabled": disabled,
                        "web-lib__label--italic": fontStyle === "italic",
                        "web-lib__label--uppercase": transform === "uppercase",
                        "web-lib__label--nowrap": nowrap,
                    },
                    className,
                )}
            >
                {children}
            </span>
        );
    };
}

function Add(baseLabel: BaseLabel, className: string) {
    return (newClassName?: string) =>
        baseLabel(classNames(className, newClassName));
}

function Curry(baseLabel: BaseLabel) {
    return (className?: string) => baseLabel(className);
}

const Colors = (baseLabel: BaseLabel) =>
    Object.assign(baseLabel("web-lib__text-color-01"), {
        TextColor01: baseLabel("web-lib__text-color-01"),
        TextColor02: baseLabel("web-lib__text-color-02"),
        TextColor03: baseLabel("web-lib__text-color-03"),
        Highlight01: baseLabel("web-lib__text-highlight-01"),
    });

const Weights = (baseComponent: BaseLabel) =>
    Object.assign(baseComponent(), {
        Reqular: Colors(Add(baseComponent, "web-lib__label--reqular")),
        MediumBold: Colors(Add(baseComponent, "web-lib__label--medium-bold")),
        Bold: Colors(Add(baseComponent, "web-lib__label--bold")),
        XBold: Colors(Add(baseComponent, "web-lib__label--xbold")),
        ...Colors(Curry(baseComponent)),
    });

const Sizes = (baseComponent: BaseLabel) =>
    Object.assign(baseComponent(), {
        Large: Weights(Add(baseComponent, "web-lib__label--large")),
        Medium: Weights(Add(baseComponent, "web-lib__label--medium")),
        Small: Weights(Add(baseComponent, "web-lib__label--small")),
        XSmall: Weights(Add(baseComponent, "web-lib__label--xsmall")),
    });

const Label = Sizes(BaseLabel);

export default Label;
