import classNames from "classnames";
import "./Paragraph.css";

export interface Props {
    className?: string;
    fontStyle?: "italic";
    transform?: "uppercase";
    children: React.ReactNode;
    disabled?: boolean;
}

type BaseParagraph = (className?: string) => (props: Props) => JSX.Element;

function BaseParagraph(baseClassName?: string) {
    return function Paragraph(props: Props) {
        const { className, fontStyle, transform, children, disabled } = props;
        return (
            <p
                className={classNames(
                    "web-lib__paragraph",
                    baseClassName,
                    {
                        "web-lib__paragraph--italic": fontStyle === "italic",
                        "web-lib__paragraph--uppercase":
                            transform === "uppercase",
                        "web-lib__paragraph--disabled": disabled,
                    },
                    className,
                )}
            >
                {children}
            </p>
        );
    };
}

function Add(baseParagraph: BaseParagraph, className: string) {
    return (newClassName?: string) =>
        baseParagraph(classNames(className, newClassName));
}

function Curry(baseParagraph: BaseParagraph) {
    return (className?: string) => baseParagraph(className);
}

const Colors = (baseParagraph: BaseParagraph) =>
    Object.assign(baseParagraph("web-lib__text-color-01"), {
        TextColor01: baseParagraph("web-lib__text-color-01"),
        TextColor02: baseParagraph("web-lib__text-color-02"),
        TextColor03: baseParagraph("web-lib__text-color-03"),
        Highlight01: baseParagraph("web-lib__text-highlight-01"),
    });

const Weights = (baseParagraph: BaseParagraph) =>
    Object.assign(baseParagraph(), {
        Reqular: Colors(Add(baseParagraph, "web-lib__paragraph--reqular")),
        MediumBold: Colors(
            Add(baseParagraph, "web-lib__paragraph--medium-bold"),
        ),
        Bold: Colors(Add(baseParagraph, "web-lib__paragraph--bold")),
        XBold: Colors(Add(baseParagraph, "web-lib__paragraph--xbold")),
        ...Colors(Curry(baseParagraph)),
    });

const Sizes = (baseParagraph: BaseParagraph) =>
    Object.assign(baseParagraph(), {
        XXLarge: Weights(Add(baseParagraph, "web-lib__paragraph--xxlarge")),
        XLarge: Weights(Add(baseParagraph, "web-lib__paragraph--xlarge")),
        Large: Weights(Add(baseParagraph, "web-lib__paragraph--large")),
        Medium: Weights(Add(baseParagraph, "web-lib__paragraph--medium")),
        Small: Weights(Add(baseParagraph, "web-lib__paragraph--small")),
        XSmall: Weights(Add(baseParagraph, "web-lib__paragraph--xsmall")),
    });

const Paragraph = Sizes(BaseParagraph);

export default Paragraph;
