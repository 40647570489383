import Asset from "../components/Asset";
import Background from "../components/Background";
import { Flex, FlexInline } from "../components/Flex";
import Icon from "../components/Icon";
import Label from "../components/Label";
import Paragraph from "../components/Paragraph";
import TextLink from "../components/TextLink";
import { useAlbertineTranslation } from "../package/albertine-i18n";
import { useTenant } from "../package/albertine-tenant";
import "./HugoBossFooter.css";

export default function HugoBossFooter() {
    const t = useAlbertineTranslation();
    const currentYear = new Date().getFullYear();
    const { tenantConfig } = useTenant();

    return (
        <>
            <Background.BookingBg02 className="hugoboss-marketing">
                <Flex.Column alignItems="center" gap="value20">
                    <Asset.HBXPConciergeLogo />

                    <Paragraph.Medium.Reqular.TextColor01 className="hugoboss-marketing__description">
                        {t("public-footer__hb-service-description")}
                    </Paragraph.Medium.Reqular.TextColor01>

                    <TextLink href={tenantConfig.serviceMarketingUrl}>
                        <FlexInline wrap="nowrap" gap="value8">
                            <Label.Medium.MediumBold.TextColor01 nowrap>
                                {t("public-footer__learn-more-about-hb")}
                            </Label.Medium.MediumBold.TextColor01>
                            <Icon.ArrowForward />
                        </FlexInline>
                    </TextLink>
                </Flex.Column>
            </Background.BookingBg02>
            <Background.BookingBg03 className="hugoboss-footer">
                <Flex.Column
                    alignItems="center"
                    className="hugoboss-footer__container"
                    gap="value16"
                >
                    <Label.XSmall.Reqular.TextColor02>
                        &copy;&nbsp;
                        {t("public-footer__copyright", {
                            currentYear,
                        })}
                    </Label.XSmall.Reqular.TextColor02>

                    <Asset.PoweredByAlbertine className="hugoboss-footer__powered-by-albertine" />

                    <Label.XSmall.Reqular.TextColor02>
                        <a href={`mailto:${tenantConfig.emailService}`}>
                            {tenantConfig.emailService}
                        </a>
                    </Label.XSmall.Reqular.TextColor02>
                </Flex.Column>
            </Background.BookingBg03>
        </>
    );
}
