import React from "react";
import classNames from "classnames";
import "./Tag.css";

type Props = {
    className?: string;
    children: React.ReactNode;
    nowrap?: boolean;
};

type BaseTag = (className?: string) => (props: Props) => JSX.Element;

function BaseTag(baseClassName?: string) {
    return function Tag(props: Props) {
        const { className, children, nowrap } = props;
        return (
            <span
                className={classNames(
                    "web-lib__tag",
                    baseClassName,
                    className,
                    { "web-lib__tag--nowrap": nowrap },
                )}
            >
                {children}
            </span>
        );
    };
}

const Sizes = (baseComponent: BaseTag) =>
    Object.assign(baseComponent(""), {
        Primary: baseComponent("web-lib__tag--primary"),
        Secondary: baseComponent("web-lib__tag--secondary"),
    });

const Tag = Sizes(BaseTag);

export default Tag;
