{
    "allow_notifications": "Sende mir Benachrichtigungen",
    "albertine-user-name": "Albertine",
    "conversations_screen_title": "Deine Unterhaltungen",
    "bookings_screen_title": "Deine Buchungen",
    "welcome_user": "Willkommen, {{username}}!",
    "navigation_title_discover": "Entdecken",
    "navigation_title_bookings": "Buchungen",
    "navigation_title_conversations": "Unterhaltungen",
    "navigation_title_profile": "Profil",
    "chat_input__placeholder": "Schreibe deine Nachricht...",
    "login__albertine_slogan": "Der Lifestyle-Concierge nur auf Einladung",
    "login__get_started": "Erste Schritte",
    "login__join_text": "Treten Sie der Warteliste bei",
    "login_form__title_sms":"Beginnen wir mit Ihrer Telefonnummer",
    "login_form__description_sms":"Bitte geben Sie dieselbe Telefonnummer ein, mit der Sie sich registriert haben.",
    "login_form__title_code":"Geben Sie Ihren Bestätigungscode ein",
    "login_form__email_login_link_text": "Senden Sie mir stattdessen den Anmeldelink per E-Mail",
    "login_form__description_code":"Ihr Code sollte innerhalb der nächsten 30 Sekunden per SMS eintreffen.",
    "login_form__continue":"Weiter",
    "login_form__verify":"Bestätigen",
    "login_form__title_email": "Mit E-Mail anmelden",
    "login_form__description_email": "Geben Sie die E-Mail-Adresse ein, mit der Sie sich registriert haben, um einen Anmeldelink zu erhalten.",
    "login_form__title_link": "Bitte überprüfen Sie Ihre E-Mails auf einen Anmeldelink",
    "login_form__description_link": "Wenn Sie sich immer noch nicht anmelden können, überprüfen Sie bitte, ob die eingegebene Telefonnummer und E-Mail-Adresse mit denen übereinstimmen, die Sie bei der Registrierung für Ihre Mitgliedschaft verwendet haben.\n\nFür weitere Informationen können Sie uns eine E-Mail an membership@albertineapp.com senden.\n\nWenn Sie noch kein Albertine-Mitglied sind, laden wir Sie ein, sich auf unsere Warteliste zu setzen.",
    "login_form__open_email": "E-Mail öffnen",
    "login_form__tag_sign_in":"Anmelden",
    "login_form__tag_verification":"Bestätigung",
    "login_form__tag_error_incorrect_number": "Falsche Nummer",
    "login_form__tag_error_incorrect_code": "Falscher Code",
    "errorMessage": "Etwas ist schiefgelaufen",
    "conversations__your-requests": "Deine Anfragen",
    "conversations__closed-requests": "Geschlossene Anfragen",
    "conversations__request-or-send": "Stelle eine Anfrage oder sende eine Nachricht",
    "conversations__new-messages": "Neue Nachrichten",
    "conversations__request-received": "Neue Anfrage erhalten",
    "conversations__requests-review": "Bitte überprüfen",
    "conversations__requests-in-progress": "In Bearbeitung",
    "conversations__requests-confirmed": "Bestätigte Anfragen",
    "conversations__request-confirmed": "Bestätigt",
    "conversations__deadline-next-update": "Nächstes Update: {{deadlineText}}",
    "conversations__deadline-overdue": "Bald",
    "conversations__deadline-minutes:one": "{{count}} Minute",
    "conversations__deadline-minutes:other": "{{count}} Minuten",
    "conversations__deadline-hours:one": "{{count}} Stunde",
    "conversations__deadline-hours:other": "{{count}} Stunden",
    "conversations__deadline-days:one": "{{count}} Tag",
    "conversations__deadline-days:other": "{{count}} Tage",
    "chat__header": "Albertine",
    "chat__all_messages": "Alle Nachrichten",
    "chat__new_request": "Neue Anfrage",
    "chat__reply_title": "Antwort",
    "chat__proposal-title": "Optionen",
    "chat__confirmed-booking-title": "Bestätigt",
    "today": "Heute",
    "yesterday": "Gestern",
    "public_booking__confirmed": "Bestätigt",
    "public_booking__address-visit-website": "Website besuchen",
    "public_booking__address-call": "Anrufen",
    "public_booking__address-view-on-map": "Auf der Karte anzeigen",
    "public_booking__attachments": "Anhänge",
    "public_booking__private-attachments-description": "Zum Schutz Ihrer persönlichen Daten können einige Anhänge nur über die Albertine-App geöffnet werden.",
    "public_booking__private-attachments-private": "(privat)",
    "public_booking__accommodation-details": "Unterkunftsdetails",
    "public_booking__accommodation-details__check-in": "Check-in",
    "public_booking__accommodation-details__check-out": "Check-out",
    "public_booking__accommodation-details__details": "Details",
    "public_booking__concierge-benefit": "Albertine-Vorteil",
    "public_booking__total-price": "Gesamtpreis",
    "public_booking__booking-policy": "Buchungsrichtlinien",
    "public_booking__visible-in-app-only": "Nur in der App sichtbar",
    "public_booking__notes-from-the-team": "Notizen vom Team",
    "public_proposal__options": "Optionen",
    "public-learn-more__description": "Albertine ist ein Concierge-Service nur auf Einladung, der eine begrenzte Anzahl von Mitgliedern auf Empfehlung akzeptiert.",
    "public-learn-more__request": "Erfahren Sie mehr & fordern Sie eine Einladung an",
    "public-footer__copyright": "Albertine, {{currentYear}}. Alle Rechte vorbehalten.",
    "public-footer__hb-service-description": "Teil von HUGO BOSS XP, dem exklusiven Mitgliedschaftsprogramm, das Zugang zur Welt von BOSS und HUGO ermöglicht.",
    "public-footer__learn-more-about-hb": "Erfahren Sie mehr über Hugo Boss XP",
    "page-not-found__title": "Seite nicht gefunden",
    "page-not-found__heading": "Es sieht so aus, als ob dieser Inhalt abgelaufen ist",
    "page-not-found__description": "Aus Sicherheitsgründen läuft der geteilte Inhalt nach 90 Tagen ab",
    "number-of-quests:one": "{{count}} Gast",
    "number-of-quests:other": "{{count}} Gäste",
    "number-of-nights:one": "{{count}} Nacht",
    "number-of-nights:other": "{{count}} Nächte",
    "notifications__tag": "Benachrichtigungen",
    "notifications__title": "Bitte aktivieren Sie Benachrichtigungen, damit wir kommunizieren können",
    "notifications__description": "Wir kommunizieren häufig über zeitkritische Themen wie Last-Minute-Buchungen und möchten nicht, dass Sie etwas verpassen.",
    "notifications__button": "Weiter",
    "location__tag": "Standortfreigabe",
    "location__title": "Bitte erlauben Sie die Standortfreigabe für Anleitungen und Empfehlungen",
    "location__description": "Bitte erlauben Sie die Standortfreigabe auf dem nächsten Bildschirm, damit wir Ihnen Anleitungen und Empfehlungen geben können, wo immer Sie sich auf der Welt befinden.",
    "location__button": "Weiter",
    "profile__profile": "Profil",
    "profile__logout": "Abmelden",
    "profile__terms-and-conditions": "Geschäftsbedingungen",
    "profile__privacy-policy": "Datenschutzrichtlinie",
    "profile__membership": "Mitgliedschaft",
    "profile__member-since": "Mitglied seit {{ memberSince }}",
    "profile__membership-renewal": "Die Mitgliedschaft verlängert sich am {{ membershipRenewal }}",
    "profile__footer-copyright": "© {{ currentYear }} Albertine Ltd. Alle Rechte vorbehalten",
    "profile__feedback": "Rückmeldung",
    "email_templates": {
      "message_renotification_subject": "Nachricht von {{serviceName}}",
      "email_sign_in_link_subject": "Anmelden bei {{serviceName}}"
    },
    "bookings__bookings": "Buchungen",
    "bookings__your_confirmed_bookings": "Ihre bestätigten Buchungen",
    "bookings__your_history": "Ihre Historie",
    "bookings__confirmed": "bestätigt",
    "bookings__empty_state_title": "Wo geht's als Nächstes?",
    "bookings__empty_state_description": "Ihr Team steht Ihnen zur Verfügung, um bei Buchungen und Empfehlungen zu helfen.",
    "bookings__empty_state_button": "eine Anfrage stellen",
    "albertine__terms-and-conditions": "Albertine App – Allgemeine Geschäftsbedingungen",
    "albertine__privacy-policy": "Albertine-App – Datenschutzrichtlinie",
    "back" : "Zurück",
    "share": "Teilen"
}
