import { z } from "zod";
import { enGB, fr, de, Locale } from "date-fns/locale";

export const defaultServiceLanguage = "en";
export const ServiceLanguage = z.enum(["de", "fr", defaultServiceLanguage]);

export type ServiceLanguage = z.infer<typeof ServiceLanguage>;

export const ServiceLanguageMap: { [key in ServiceLanguage]: string } = {
    en: "English",
    de: "German",
    fr: "French",
};

export const ServiceLanguageToLocaleMap: { [key in ServiceLanguage]: Locale } =
    {
        en: enGB,
        de,
        fr,
    };

export function getLocale(
    possibleLocale: string | undefined | null,
): ServiceLanguage {
    const parsed = ServiceLanguage.safeParse(possibleLocale);

    if (parsed.success) {
        return parsed.data;
    }

    return defaultServiceLanguage;
}
