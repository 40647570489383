{
    "allow_notifications": "Envoyez-moi des notifications",
    "albertine-user-name": "Albertine",
    "conversations_screen_title": "Vos conversations",
    "bookings_screen_title": "Vos réservations",
    "welcome_user": "Bienvenue, {{username}} !",
    "navigation_title_discover": "Découvrir",
    "navigation_title_bookings": "Réservations",
    "navigation_title_conversations": "Conversations",
    "navigation_title_profile": "Profil",
    "chat_input__placeholder": "Tapez votre message...",
    "login__albertine_slogan": "Le concierge de style de vie sur invitation uniquement",
    "login__get_started": "Commencer",
    "login__join_text": "Rejoindre la liste d'attente",
    "login_form__title_sms":"Commençons par votre numéro de téléphone",
    "login_form__description_sms":"Veuillez entrer le même numéro de téléphone que celui utilisé lors de votre inscription.",
    "login_form__title_code":"Entrez votre code de vérification",
    "login_form__email_login_link_text": "Envoyez-moi plutôt un lien de connexion par e-mail",
    "login_form__description_code":"Votre code devrait arriver par SMS dans les 30 prochaines secondes.",
    "login_form__continue":"Continuer",
    "login_form__verify":"Vérifier",
    "login_form__title_email": "Se connecter avec l'e-mail",
    "login_form__description_email": "Saisissez l'adresse e-mail utilisée lors de votre inscription pour recevoir un lien de connexion.",
    "login_form__title_link": "Veuillez vérifier votre e-mail pour obtenir un lien de connexion",
    "login_form__description_link": "Si vous ne parvenez toujours pas à vous connecter, veuillez vérifier que le numéro de téléphone et l'e-mail saisis correspondent à ceux que vous avez utilisés lors de votre inscription.\n\nPour plus d'informations, vous pouvez nous envoyer un e-mail à <0>membership@albertineapp.com</0>.\n\nSi vous n'êtes pas encore membre d'Albertine, nous vous invitons à rejoindre notre liste d'attente.",
    "login_form__open_email": "Ouvrir l'e-mail",
    "login_form__tag_sign_in":"Se connecter",
    "login_form__tag_verification":"Vérification",
    "login_form__tag_error_incorrect_number": "Numéro incorrect",
    "login_form__tag_error_incorrect_code": "Code incorrect",
    "errorMessage": "Quelque chose s'est mal passé",
    "conversations__your-requests": "Vos demandes",
    "conversations__closed-requests": "Demandes fermées",
    "conversations__request-or-send": "Faire une demande ou envoyer un message",
    "conversations__new-messages": "Nouveaux messages",
    "conversations__request-received": "Nouvelle demande reçue",
    "conversations__requests-review": "Veuillez vérifier",
    "conversations__requests-in-progress": "En cours",
    "conversations__requests-confirmed": "Demandes confirmées",
    "conversations__request-confirmed": "Confirmé",
    "conversations__deadline-next-update": "Prochaine mise à jour : {{deadlineText}}",
    "conversations__deadline-overdue": "Bientôt",
    "conversations__deadline-minutes:one": "{{count}} minute",
    "conversations__deadline-minutes:other": "{{count}} minutes",
    "conversations__deadline-hours:one": "{{count}} heure",
    "conversations__deadline-hours:other": "{{count}} heures",
    "conversations__deadline-days:one": "{{count}} jour",
    "conversations__deadline-days:other": "{{count}} jours",
    "chat__header": "Albertine",
    "chat__all_messages": "Tous les messages",
    "chat__new_request": "Nouvelle demande",
    "chat__reply_title": "Répondre",
    "chat__proposal-title": "Options",
    "chat__confirmed-booking-title": "Confirmé",
    "today": "Aujourd'hui",
    "yesterday": "Hier",
    "public_booking__confirmed": "Confirmé",
    "public_booking__address-visit-website": "Visiter le site",
    "public_booking__address-call": "Appeler",
    "public_booking__address-view-on-map": "Voir sur la carte",
    "public_booking__attachments": "Pièces jointes",
    "public_booking__private-attachments-description": "Pour assurer la sécurité de vos données personnelles, certaines pièces jointes ne peuvent être ouvertes que via l'application Albertine.",
    "public_booking__private-attachments-private": "(privé)",
    "public_booking__accommodation-details": "Détails de l'hébergement",
    "public_booking__accommodation-details__check-in": "Enregistrement",
    "public_booking__accommodation-details__check-out": "Départ",
    "public_booking__accommodation-details__details": "Détails",
    "public_booking__concierge-benefit": "Avantage Albertine",
    "public_booking__total-price": "Prix total",
    "public_booking__booking-policy": "Politique de réservation",
    "public_booking__visible-in-app-only": "Visible uniquement dans l'application",
    "public_booking__notes-from-albertine": "Notes de l'équipe",
    "public_proposal__options": "Options",
    "public-learn-more__description": "Albertine est un service de conciergerie réservé sur invitation, qui accepte un nombre limité de membres sur recommandation.",
    "public-learn-more__request": "En savoir plus & demander une invitation",
    "public-footer__copyright": "Albertine, {{currentYear}}. Tous droits réservés.",
    "public-footer__hb-service-description": "Faisant partie de HUGO BOSS XP, le programme d'adhésion exclusif qui ouvre les portes de l'univers de BOSS et HUGO.",
    "public-footer__learn-more-about-hb": "En savoir plus sur Hugo Boss XP",
    "page-not-found__title": "Page introuvable",
    "page-not-found__heading": "Il semble que ce contenu a expiré",
    "page-not-found__description": "Pour des raisons de sécurité, le contenu partagé expire après 90 jours",
    "number-of-quests:one": "{{count}} invité",
    "number-of-quests:other": "{{count}} invités",
    "number-of-nights:one": "{{count}} nuit",
    "number-of-nights:other": "{{count}} nuits",
    "profile__profile": "Le profil",
    "profile__logout": "Déconnexion",
    "profile__terms-and-conditions": "Termes et conditions",
    "profile__privacy-policy": "Politique de confidentialité",
    "profile__membership": "Adhésion",
    "profile__member-since": "Membre depuis {{ memberSince }}",
    "profile__membership-renewal": "L'adhésion se renouvelle le {{ membershipRenewal }}",
    "profile__footer-copyright": "© {{ currentYear }} Albertine Ltd. All rights reserved",
    "profile__feedback": "Retour",
    "notifications__tag": "Notifications",
    "notifications__title": "Veuillez activer les notifications afin que nous puissions communiquer",
    "notifications__description": "Nous communiquons souvent sur des sujets sensibles au temps, comme les réservations de dernière minute, et nous ne voulons pas que vous manquiez d'informations.",
    "notifications__button": "Continuer",
    "location__tag": "Partage de localisation",
    "location__title": "Veuillez autoriser le partage de localisation pour des conseils et des recommandations",
    "location__description": "Veuillez autoriser le partage de localisation à l'écran suivant, afin que nous puissions vous fournir des conseils et des recommandations, peu importe où vous êtes dans le monde.",
    "location__button": "Continuer",
    "email_templates": {
        "message_renotification_subject": "Message de {{serviceName}}",
        "email_sign_in_link_subject": "Se connecter à {{serviceName}}"
    },
    "bookings__bookings": "Réservations",
    "bookings__your_confirmed_bookings": "vos réservations confirmées",
    "bookings__your_history": "votre historique",
    "bookings__confirmed": "confirmé",
    "bookings__empty_state_title": "Où ensuite?",
    "bookings__empty_state_description": "Votre équipe est à votre disposition pour vous aider avec les réservations et les recommandations.",
    "bookings__empty_state_button": "faire une demande",
    "albertine__terms-and-conditions": "Application Albertine - Mentions légales",
    "albertine__privacy-policy": "Application Albertine - Politique de confidentialité",
    "back" : "Retour",
    "share": "Partager"
}
