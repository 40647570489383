import Background from "../components/Background";
import { Flex } from "../components/Flex";
import Label from "../components/Label";
import { useAlbertineTranslation } from "../package/albertine-i18n";
import { useTenant } from "../package/albertine-tenant";
import "./AlbertineFooter.css";

export default function AlbertineFooter() {
    const t = useAlbertineTranslation();
    const currentYear = new Date().getFullYear();
    const { tenantConfig } = useTenant();
    return (
        <Background.BookingBg03 className="albertine-footer">
            <Flex.Column
                alignItems="center"
                className="albertine-footer__container"
                gap="value8"
            >
                <Label.XSmall.Reqular.TextColor02>
                    &copy;&nbsp;
                    {t("public-footer__copyright", {
                        currentYear,
                    })}
                </Label.XSmall.Reqular.TextColor02>
                <Label.XSmall.Reqular.TextColor02>
                    <a href={`mailto:${tenantConfig.emailService}`}>
                        {tenantConfig.emailService}
                    </a>
                </Label.XSmall.Reqular.TextColor02>
            </Flex.Column>
        </Background.BookingBg03>
    );
}
