import classNames from "classnames";
import "./Background.css";

export interface Props {
    className?: string;
    children: React.ReactNode;
    textColorInversable?: boolean;
}

function BaseBackground(baseClassName?: string) {
    return function Background(props: Props) {
        const { className, children, textColorInversable = false } = props;
        return (
            <div
                className={classNames(
                    "web-lib__background",
                    {
                        "text-color-inversable": textColorInversable,
                    },
                    baseClassName,
                    className,
                )}
            >
                {children}
            </div>
        );
    };
}

const Background = {
    BookingBg01: BaseBackground("web-lib-booking-bg-01"),
    BookingBg02: BaseBackground("web-lib-booking-bg-02"),
    BookingBg03: BaseBackground("web-lib-booking-bg-03"),
    BookingBg04: BaseBackground("web-lib-booking-bg-04"),
    ProfileBg04: BaseBackground("web-lib-profile-bg-04"),
};

export default Background;
