import Background from "../components/Background";
import ContentCenter from "../components/ContentCenter";
import Paragraph from "../components/Paragraph";
import Tag from "../components/Tag";
import {
    AlbertineI18nProvider,
    ServiceLanguage,
    useAlbertineTranslation,
} from "../package/albertine-i18n";
import { Tenant } from "../package/albertine-tenant";
import {
    useTenant,
    TenantProvider,
} from "../package/albertine-tenant/components";
import AlbertineFooter from "./AlbertineFooter";
import AlbertineLearnMore from "./AlbertineLearnMore";
import HugoBossFooter from "./HugoBossFooter";
import { Screen, AlbertineBackgroundImageContainer } from "./LandingScreen";
import "./NotFoundScreen.css";

function NotFoundContent() {
    const t = useAlbertineTranslation();
    const { tenant } = useTenant();

    const notFoundDetails = (
        <div className="not-found__details">
            <Tag.Primary className="not-found__tag">
                {t("page-not-found__title")}
            </Tag.Primary>
            <Paragraph.XLarge.Bold.TextColor01>
                {t("page-not-found__heading")}
            </Paragraph.XLarge.Bold.TextColor01>

            <Paragraph.Large.Reqular.TextColor01>
                {t("page-not-found__description")}
            </Paragraph.Large.Reqular.TextColor01>
        </div>
    );
    return (
        <Screen>
            {tenant === "albertine" && (
                <>
                    <AlbertineBackgroundImageContainer>
                        {notFoundDetails}
                    </AlbertineBackgroundImageContainer>
                    <AlbertineLearnMore />
                    <AlbertineFooter />
                </>
            )}

            {tenant === "hugoboss" && (
                <>
                    <Background.BookingBg01 className="not-found__container">
                        <ContentCenter>{notFoundDetails}</ContentCenter>
                    </Background.BookingBg01>
                    <HugoBossFooter />
                </>
            )}
        </Screen>
    );
}

export type NotFoundScreenProps = {
    lang: ServiceLanguage;
    tenant: Tenant;
};

export default function NotFoundScreen(props: NotFoundScreenProps) {
    const { lang, tenant } = props;

    return (
        <AlbertineI18nProvider initialLanguage={lang}>
            <TenantProvider value={tenant}>
                <NotFoundContent />
            </TenantProvider>
        </AlbertineI18nProvider>
    );
}
