import classNames from "classnames";
import React from "react";
import "./Icon.css";

/* Symbol name from: https://fonts.google.com/icons */
type MaterialDesignIconName =
    | "add"
    | "add_link"
    | "add_photo_alternate"
    | "arrow_back"
    | "arrow_forward"
    | "arrow_downward"
    | "arrow_upward"
    | "assignment_add"
    | "attach_file"
    | "attach_file_add"
    | "autorenew"
    | "calendar_month"
    | "calendar_today"
    | "call"
    | "chevron_left"
    | "chevron_right"
    | "check"
    | "close"
    | "content_copy"
    | "content_paste"
    | "commute"
    | "crop"
    | "description"
    | "delete"
    | "drag_indicator"
    | "edit"
    | "error"
    | "event_available"
    | "expand_less"
    | "expand_more"
    | "favorite"
    | "flight"
    | "filter_list"
    | "forum"
    | "import_contacts"
    | "label"
    | "link"
    | "list"
    | "logout"
    | "lock"
    | "local_activity"
    | "local_bar"
    | "location_on"
    | "handshake"
    | "hotel"
    | "keyboard_tab"
    | "notifications"
    | "notification_add"
    | "mark_unread_chat_alt"
    | "mic"
    | "more_vert"
    | "more_horiz"
    | "open_in_new"
    | "picture_as_pdf"
    | "person_add"
    | "photo_frame"
    | "photo_camera"
    | "priority_high"
    | "problem"
    | "refresh"
    | "restore_from_trash"
    | "restaurant_menu"
    | "reply"
    | "rotate_right"
    | "route"
    | "schedule"
    | "send"
    | "search"
    | "share"
    | "shopping_bag"
    | "sensors"
    | "settings"
    | "spa"
    | "star_rate"
    | "thumb_up"
    | "thumb_down"
    | "train"
    | "undo"
    | "unfold_more"
    | "unfold_less"
    | "unpublished"
    | "vpn_key"
    | "question_mark";

type BaseIcon = (
    className?: string,
) => (props: Props & IconProps) => JSX.Element;

export interface Props {
    className?: string;
}

export interface IconProps {
    style?: React.CSSProperties;
}

function BaseMaterialIcon(name: MaterialDesignIconName) {
    return function BaseIcon(baseClassName?: string) {
        return function Icon(props: Props & IconProps) {
            const { className, style } = props;

            return (
                <span
                    className={classNames(
                        "web-lib__icon",
                        "material-symbols-outlined",
                        baseClassName,
                        className,
                    )}
                    style={style}
                >
                    {name}
                </span>
            );
        };
    };
}

function Add(baseIcon: BaseIcon, className: string) {
    return (newClassName?: string) =>
        baseIcon(classNames(className, newClassName));
}

const Fill = (baseComponent: BaseIcon) =>
    Object.assign(baseComponent(), {
        Fill: baseComponent("web-lib__icon--fill"),
    });

const MdColors = (baseComponent: BaseIcon) =>
    Object.assign(baseComponent(), {
        Gray0: Fill(Add(baseComponent, "web-lib__text-color--gray-0")),
        Gray10: Fill(Add(baseComponent, "web-lib__text-color--gray-10")),
        Gray20: Fill(Add(baseComponent, "web-lib__text-color--gray-20")),
        Gray30: Fill(Add(baseComponent, "web-lib__text-color--gray-30")),
        Gray40: Fill(Add(baseComponent, "web-lib__text-color--gray-40")),
        Gray50: Fill(Add(baseComponent, "web-lib__text-color--gray-50")),
        Gray60: Fill(Add(baseComponent, "web-lib__text-color--gray-60")),
        Gray70: Fill(Add(baseComponent, "web-lib__text-color--gray-70")),
        Gray80: Fill(Add(baseComponent, "web-lib__text-color--gray-80")),
        Gray90: Fill(Add(baseComponent, "web-lib__text-color--gray-90")),
        Gray95: Fill(Add(baseComponent, "web-lib__text-color--gray-95")),
        Gray100: Fill(Add(baseComponent, "web-lib__text-color--gray-100")),
        PrimaryGreen60: Fill(
            Add(baseComponent, "web-lib__text-color--primary-green-60"),
        ),
        Brown60: Fill(Add(baseComponent, "web-lib__text-color--brown-60")),
        Brown80: Fill(Add(baseComponent, "web-lib__text-color--brown-80")),
        Brown95: Fill(Add(baseComponent, "web-lib__text-color--brown-95")),
        ForestGreen60: Fill(
            Add(baseComponent, "web-lib__text-color--forest-green-60"),
        ),
        ForestGreen80: Fill(
            Add(baseComponent, "web-lib__text-color--forest-green-80"),
        ),
        ForestGreen95: Fill(
            Add(baseComponent, "web-lib__text-color--forest-green-95"),
        ),
        Indigo60: Fill(Add(baseComponent, "web-lib__text-color--indigo-60")),
        Pink60: Fill(Add(baseComponent, "web-lib__text-color--pink-60")),
        Blue60: Fill(Add(baseComponent, "web-lib__text-color--blue-60")),
        Yellow60: Fill(Add(baseComponent, "web-lib__text-color--yellow-60")),
        Error60: Fill(Add(baseComponent, "web-lib__text-color--error-60")),
        Warning60: Fill(Add(baseComponent, "web-lib__text-color--warning-60")),
    });

const MdSizes = (baseComponent: BaseIcon) =>
    Object.assign(baseComponent(), {
        Large: MdColors(Add(baseComponent, "web-lib__icon--large")),
        Medium: MdColors(Add(baseComponent, "web-lib__icon--medium")),
        Small: MdColors(Add(baseComponent, "web-lib__icon--small")),
        XSmall: MdColors(Add(baseComponent, "web-lib__icon--xsmall")),
    });

const BaseMaterialIconDefault = (name: MaterialDesignIconName) =>
    Fill(Add(BaseMaterialIcon(name), "web-lib__text-color--gray-10"));

const MaterialIcon = (name: MaterialDesignIconName) =>
    Object.assign(
        BaseMaterialIconDefault(name),
        MdSizes(BaseMaterialIcon(name)),
    );

/* TODO: together with so many colors this component grows to big that typescript limit exceeds */
const Icon = {
    /* Add: MaterialIcon("add"), */
    AddPhotoAlternate: MaterialIcon("add_photo_alternate"),
    /* AddLink: MaterialIcon("add_link"), */
    ArrowBack: MaterialIcon("arrow_back"),
    ArrowForward: MaterialIcon("arrow_forward"),
    ArrowDownward: MaterialIcon("arrow_downward"),
    /* ArrowUpward: MaterialIcon("arrow_upward"),
    AssignmentAdd: MaterialIcon("assignment_add"), */
    AttachFile: MaterialIcon("attach_file"),
    AttachFileAdd: MaterialIcon("attach_file_add"),
    AutoRenew: MaterialIcon("autorenew"),
    CalendarMonth: MaterialIcon("calendar_month"),
    CalendarToday: MaterialIcon("calendar_today"),
    Call: MaterialIcon("call"),
    ChevronLeft: MaterialIcon("chevron_left"),
    ChevronRight: MaterialIcon("chevron_right"),
    Check: MaterialIcon("check"),
    Close: MaterialIcon("close"),
    /* ContentCopy: MaterialIcon("content_copy"),
    ContentPaste: MaterialIcon("content_paste"),
    Commute: MaterialIcon("commute"),
    Crop: MaterialIcon("crop"), */
    Description: MaterialIcon("description"),
    /* Delete: MaterialIcon("delete"),
    DragIndicator: MaterialIcon("drag_indicator"),
    ExpandLess: MaterialIcon("expand_less"), */
    EventAvailable: MaterialIcon("event_available"),
    /* ExpandMore: MaterialIcon("expand_more"),
    Edit: MaterialIcon("edit"),
    Error: MaterialIcon("error"),
    FilterList: MaterialIcon("filter_list"),
    Flight: MaterialIcon("flight"), */
    Forum: MaterialIcon("forum"),
    /* Favorite: MaterialIcon("favorite"),
    ImportContacts: MaterialIcon("import_contacts"),
    Label: MaterialIcon("label"),
    Link: MaterialIcon("link"),
    List: MaterialIcon("list"),
    Logout: MaterialIcon("logout"), */
    Lock: MaterialIcon("lock"),
    /* LocalActivity: MaterialIcon("local_activity"),
    LocalBar: MaterialIcon("local_bar"), */
    LocationOn: MaterialIcon("location_on"),
    /* Handshake: MaterialIcon("handshake"),
    Hotel: MaterialIcon("hotel"),
    KeyBoardTab: MaterialIcon("keyboard_tab"),
    Notifications: MaterialIcon("notifications"),
    NotificationAdd: MaterialIcon("notification_add"),
    MarkUnreadChatAlt: MaterialIcon("mark_unread_chat_alt"), */
    Mic: MaterialIcon("mic"),
    /* MoreVert: MaterialIcon("more_vert"),
    MoreHoriz: MaterialIcon("more_horiz"),
    OpenInNew: MaterialIcon("open_in_new"),
    PhotoFrame: MaterialIcon("photo_frame"),
    PhotoCamera: MaterialIcon("photo_camera"),
    PictureAsPdf: MaterialIcon("picture_as_pdf"),
    PersonAdd: MaterialIcon("person_add"),
    PriorityHigh: MaterialIcon("priority_high"),
    Problem: MaterialIcon("problem"),
    Refresh: MaterialIcon("refresh"),
    RestoreFromTrash: MaterialIcon("restore_from_trash"),
    RestaurantMenu: MaterialIcon("restaurant_menu"), */
    Reply: MaterialIcon("reply"),
    /* RotateRight: MaterialIcon("rotate_right"),
    Route: MaterialIcon("route"),
    Schedule: MaterialIcon("schedule"), */
    Send: MaterialIcon("send"),
    /* Search: MaterialIcon("search"), */
    Share: MaterialIcon("share"),
    /* ShoppingBag: MaterialIcon("shopping_bag"),
    Sensors: MaterialIcon("sensors"),
    Settings: MaterialIcon("settings"),
    Spa: MaterialIcon("spa"),
    StarRate: MaterialIcon("star_rate"),
    ThumbUp: MaterialIcon("thumb_up"),
    ThumbDown: MaterialIcon("thumb_down"),
    Train: MaterialIcon("train"),
    Undo: MaterialIcon("undo"),
    UnfoldMore: MaterialIcon("unfold_more"),
    UnfoldLess: MaterialIcon("unfold_less"),
    Unpublished: MaterialIcon("unpublished"),
    VpnKey: MaterialIcon("vpn_key"),
    QuestionMark: MaterialIcon("question_mark"), */
};

export default Icon;

export type MdIconName = keyof typeof Icon;
